import React from 'react'
import { Header } from '../header/header';
import { PricingCard } from '../pricing-card/pricing-card';
import { Text } from '../text/text';
import './pricing.css'
import data from './data.json'

export const Pricing = ({
  header,
  text,
  setPrefilledMessage
}) => {

  return (
    <div className='pricing-section' id={'pricing'}>
      <Header headerStyle='page'>{header}</Header>
      <Text theme='text--dark'>{text}</Text>
      <div className='cards'>
        {data?.cards?.map(props =>
          <PricingCard key={props.key} setPrefilledMessage={setPrefilledMessage} props={props} />
        )}
      </div>
    </div>
  );
};