import React from 'react'
import './button.css'
import prefilledMessages from '../../utils/prefilled.messages';

const STYLES = [
    'btn--primary',
    'btn--default',
    'btn--default--bordered'
]

export const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    setPrefilledMessage
}) => {

    // check if passed dtyles exists => if not apply default syle
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[1];
    const handleClick = (e) => {
      const type = e.target.getAttribute('type');
      if(type !== 'submit') {
        const message = prefilledMessages.getPrefilledMessage(type);
        setPrefilledMessage(message);
      }
    };

    return (
        <button className={`btn ${checkButtonStyle}`} onClick={onClick ? onClick : handleClick} type={type}>
            {children}
        </button>
    );
};