import React from "react";
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { FaBars } from 'react-icons/fa'
import './navbar.css'
import Logo from '../../assets/logo.png'

export const Navbar = ({
    toggle,
    img
}) => {
    return (
        <div className='nav'>
            <LinkR className='nav-logo' to='/'>
                <img src={Logo} alt='logo' />
            </LinkR>
            <div className='mobile-icon' onClick={toggle}>
                <FaBars />
            </div>

            <ul className="nav-menu">
                <li>
                    <LinkS className="nav-link" to="home" smooth={'true'} duration={500} offset={-100}>Home</LinkS>
                </li>
                <li>
                    <LinkS className="nav-link" to="offers" smooth={'true'} duration={500}>What we offer</LinkS>
                </li>
                <li>
                    <LinkS className="nav-link" to="pricing" smooth={'true'} duration={500} offset={-80}>Pricing</LinkS>
                </li>
                <li>
                    <LinkS className="nav-link" to="contact-us" smooth={'true'} duration={500} offset={-80}>Contact Us</LinkS>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;