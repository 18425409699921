import React from 'react'
import './text.css'

const THEME = [
    'text--dark',
    'text--light',
]

export const Text = ({
    children,
    theme
}) => {
    const checkTheme = THEME.includes(theme) ? theme : THEME[0];

    return (
        <div className={`text ${checkTheme}`}>
            {children}
        </div>
    );
};