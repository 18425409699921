import React from 'react'
import './hero.css'
import HeroImage from '../../assets/hero.png'
import { Button } from '../button/button';
import { Link } from 'react-scroll';

export const Hero = ({ setPrefilledMessage }) => {
  return (
    <div className='hero-image' style={{ backgroundImage: "url(" + HeroImage + ")" }} id={'home'}>
      <div className='hero-overlay'>
        <div className='hero-content'>
          <h1>TRAIN WITH CONFIDENCE</h1>
          <p>From nutrition plans to specific technical training, we consider all aspects of your development.</p>
          <Link to="contact-us" smooth={'true'} duration={500} offset={-80}>
            <Button type={'hero-button'} setPrefilledMessage={setPrefilledMessage}>Learn more</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};