import React from 'react'
import { ContactForm } from '../contact-form/contact-form';
import { Header } from '../header/header';
import './contact-us.css'

export const ContactUs = ({prefilledMessage}) => {

    return (
        <div className='contact-us-section' id={'contact-us'}>
            <Header headerStyle='page'>Contact Us</Header>
            <ContactForm prefilledMessage={prefilledMessage} />
            {/* <Button buttonStyle='btn--primary'>Send Message</Button> */}
        </div>
    );
};