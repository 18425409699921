import React from 'react'
import { Link } from 'react-scroll';
import { Button } from '../button/button';
import { Header } from '../header/header';
import './request-consultation.css'

export const RequestConsultation = ({setPrefilledMessage}) => {
  return (
    <div className='request-consultation-section'>
      <div className='request-consultation'>
        <Header headerStyle='page'>Want to learn more about our programs?</Header>
        <Link to="contact-us" smooth={'true'} duration={500} offset={-80}>
          <Button type={'request-consultation'} setPrefilledMessage={setPrefilledMessage} buttonStyle='btn--primary'>Request a consultation</Button>
        </Link>
      </div>
    </div>
  );
};