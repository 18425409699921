import React from 'react'
import { Button } from '../button/button';
import { Header } from '../header/header';
import { Text } from '../text/text';
import './personal-training-card.css'

import PersonalTrainingImg from '../../assets/personal-training.png'
import { Link } from 'react-scroll';

// const SIZES = [
//     'offer-card--md',
//     'offer-card--lg'
// ]

// const THEME = [
//     'light',
//     'dark'
// ]

export const PersonalTrainingCard = ({
  header,
  text,
  imageSrc,
  cta,
  theme,
  size,
  setPrefilledMessage
}) => {

  header = 'Personal Training';
  text = 'Exercise with certified personal trainers whether you’re at home or in the gym.';
  cta = 'Learn more';
  imageSrc = PersonalTrainingImg;
  return (
    <div className='personal-training-card-wrapper'>
      <div className='personal-training-card offer-card--lg'>
        <Header headerStyle='header--card' theme='header--dark'>{header}</Header>
        <Text theme='text--dark'>{text}</Text>
        <Link to="contact-us" smooth={'true'} duration={500} offset={-80}>
          <Button setPrefilledMessage={setPrefilledMessage} type={'personal-training'} buttonStyle='btn--primary'>{cta}</Button>
        </Link>
        <img className='personal-training-image' src={require('../../assets/personal-training.png')} alt='personal-training' />
      </div>
    </div>
  );
};