import React from 'react'
import './footer.css'
import { SocialIcon } from 'react-social-icons';

export const Footer = () => {

  return (
    <footer>
      <hr />
      <div className='footer-content'>
        <div className='footer-social'>
          <SocialIcon bgColor='#FFF' style={{ height: 25, width: 25 }} url="https://www.instagram.com/thatcher.cory/" />
          <SocialIcon bgColor='#FFF' style={{ height: 25, width: 25 }} url="https://www.linkedin.com/in/cory-thatcher-178622119/" />
        </div>
        <p className='footer-copyright'>
          © 2022 Anywhere We Roam. All rights reserved.
          Designed and Developed by <a style={{ color: 'rgb(230, 68, 125)', }} href='https://www.ideators.io'>ideators.io</a>
        </p>
      </div>
    </footer>
  );
};