import React from "react";
import { Link as LinkS } from 'react-scroll'
import { FaTimes } from 'react-icons/fa'
import './sidebar.css'

export const Sidebar = ({
  isOpen,
  toggle
}) => {
  const isOpenStyle = isOpen ? 'sidebar--open' : 'sidebar--closed'

  return (
    <div className={`sidebar ${isOpenStyle}`} isOpen={isOpen} onClick={toggle}>
      <div className="icon" onClick={toggle}>
        <FaTimes className="close-icon" />
      </div>
      <div className="sidebar-wrapper">
        <ul>
          <li>
            <LinkS className="nav-link" to="home" onClick={toggle} smooth={'true'} duration={500} offset={-80}>Home</LinkS>
          </li>
          <li>
            <LinkS className="nav-link" to="offers" onClick={toggle} smooth={'true'} duration={500} offset={-80}>What we offer</LinkS>
          </li>
          <li>
            <LinkS className="nav-link" to="pricing" onClick={toggle} smooth={'true'} duration={500} offset={-120}>Pricing</LinkS>
          </li>
          <li>
            <LinkS className="nav-link" to="contact-us" onClick={toggle} smooth={'true'} duration={500} offset={-120}>Contact Us</LinkS>
          </li>
        </ul>
      </div>
    </div>
  );
}