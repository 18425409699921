import React from 'react'
import { Header } from '../header/header';
import { Text } from '../text/text';
import './what-we-offer.css'

export const WhatWeOffer = ({
    header,
    text
}) => {
    return (
        <div className='what-we-offer' id={'offers'}>
            <Header headerStyle='header--page' theme='header--dark'>{header}</Header>
            <Text theme='text--dark'>{text}</Text>
        </div>
    );
};