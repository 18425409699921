import React from 'react'
import { useState } from 'react';
import { Link } from 'react-scroll';
import { Button } from '../button/button';
import { Header } from '../header/header';
import './pricing-card.css'
import './select.css'

export const PricingCard = ({
  props,
  setPrefilledMessage
}) => {
  const theme = 'dark';
  const [amount, setAmount] = useState(props?.options?.[0].value);

  const handleChange = event => {
    console.log(event.target.value);
    setAmount(event.target.value);
  };

  var buttonStyle = theme === 'dark' ? 'btn--primary' : 'btn--default'
  const visible = props?.options?.length > 1 ? 'visible' : 'hidden';

  return (
    <div className='pricing-card' style={{ background: '#F2F2F2' }}>
      <Header headerStyle='header--pricing' theme={`header--${theme}`}>{props?.header}</Header>
      <div className='price'>
        <div className='amount'>${amount}</div>
        <div className='billing'>
          <div>/{props?.frequency}</div>
          <div>+ tax</div>
        </div>
      </div>
      <div className='priceNotes'>
        {props?.notes?.map(note =>
          <div>{note}</div>
        )}
      </div>
      <div className='selectWrapper' style={{ visibility: `${visible}` }}>
        <select
          className={`select select--default--bordered`}
          onChange={handleChange}
        >
          {props?.options?.map(option =>
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          )}
        </select>
      </div>
      <div className='features'>
        <ul>
          {props?.perks?.map(perk =>
            <li>{perk}</li>
          )}
        </ul>
      </div>
      <Link to="contact-us" smooth={'true'} duration={500} offset={-80}>
        <Button type={props?.type} setPrefilledMessage={setPrefilledMessage} buttonStyle={buttonStyle}>{props?.cta}</Button>
      </Link>
    </div>
  );
};