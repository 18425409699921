import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper";

import { TestimonialCard } from '../testimonial-card/testimonial-card';
import './testimonial-slider.css'


export const TestimonialSlider = () => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={80}
      className="mySwiper"
      navigation={true}
      modules={[Autoplay, Navigation]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 60,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 80,
        },
      }}
    >
      <SwiperSlide>
        <TestimonialCard
          avatar='https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
          content='Cory is so thorough and makes sure his workout plans are personalized for your needs. He genuinely cares about getting you to reach your goals. I am very happy with my progress and seeing significant strength improvements!' 
          name='Danka' />
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialCard
          avatar='https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
          content='I wanted someone to help me start my fitness journey, guiding me with nutrition, coach me through exercises and also be someone I could talk to. Cory was definitely the trainer! He pushed me to work harder every day. I lost near 70lbs in 5 months. Tough journey, but definitely worth it.'
          name='Ramzi'
        />
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialCard
          avatar='https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
          content='I have been training with Cory for many years and highly recommend him to anyone looking for a personal trainer. He has a wealth of knowledge and is always improving himself by attending workshops and courses to stay on top of the latest exercises, training programs and health information.'
          name='John'
        />
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialCard
          avatar='https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
          content='The whole experience was top notch and Cory really cared that I hit my goal. The workouts were challenging from day one and I loved it. Cory made sure the technique is perfect at the same time squeezing everything I had on every single set, every single rep. Cory also followed up on weekends and other times making sure I was recovering well and eating/resting properly. He was always personable, professional, and easy to talk to.'
          name='Filipp'
        />
      </SwiperSlide>
    </Swiper>
  );
};