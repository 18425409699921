import React from 'react'
import { OfferCard } from '../offer-card/offer-card';
import './offers.css'
import OfferProgramsImg from '../../assets/offer-programs.png'
import OfferNutritionImg from '../../assets/offer-nutritions.png'

export const Offers = ({ setPrefilledMessage }) => {
  return (
    <div className='offers'>
      <OfferCard
        setPrefilledMessage={setPrefilledMessage}
        header='Programs'
        type='programs'
        text='Professionally designed programs take the guesswork out of exercising.'
        cta='Learn more'
        imageSrc={OfferProgramsImg}
        theme='light' />
      <OfferCard
        setPrefilledMessage={setPrefilledMessage}
        header='Nutrition Advisory'
        type='nutrition-advisory'
        text='Improve your eating habits and accelerate your fitness results with a personalized nutrition plan.'
        cta='Learn more'
        imageSrc={OfferNutritionImg}
        theme='dark' />
    </div>
  );
};