
import React, { useState } from 'react'

import { WhatWeOffer } from '../components/what-we-offer/what-we-offer';
import { Navbar } from '../components/navbar/navbar';
import { Sidebar } from '../components/sidebar/sidebar';
import { Footer } from '../components/footer/footer';
import { ContactUs } from '../components/contact-us/contact-us';
import { RequestConsultation } from '../components/request-consultation/request-consultation';
import { Testimonials } from '../components/testimonials/testimonials';
import { PersonalTrainingCard } from '../components/personal-training-card/personal-training-card';
import { Offers } from '../components/offers/offers';
import { TakeAssessment } from '../components/take-assessment/take-assessment';
import { Hero } from '../components/hero/hero';
import { Pricing } from '../components/pricing/pricing';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prefilledMessage, setPrefilledMessage] = useState('');

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Hero setPrefilledMessage={setPrefilledMessage} />
        <TakeAssessment header='Created to help you live a better, happier, healthier life.' text='At Thatcher Fitness we are committed to providing guidance and support to help you achieve your desired results. We will walk with you through your fitness journey setting achievable and realistic goals, tracking progress, establishing a consistent exercise routine and improving your eating habits.' cta='Check our plans' />
        {/* <BgVideo /> */}
        <div className='separator'></div>
        <WhatWeOffer header='What we offer' text='We offer Toronto’s best fitness programs, personal training and nutritional support for all levels of experience. Come check us out!' />
        <Offers setPrefilledMessage={setPrefilledMessage} />
        <PersonalTrainingCard setPrefilledMessage={setPrefilledMessage} />
        <Pricing setPrefilledMessage={setPrefilledMessage} header="Straight Forward Pricing" />
        {/* <div className='separator'></div> */}
        <Testimonials />
        <RequestConsultation setPrefilledMessage={setPrefilledMessage} />
        <ContactUs prefilledMessage={prefilledMessage} />
        <Footer />
      </div>
    </>
  );
};

export default Home;