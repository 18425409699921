import React from 'react'
import './testimonial-card.css'

export const TestimonialCard = ({
    avatar,
    content,
    name,
    title
}) => {
    return (
        <div className='testimonial-card'>
            {/* <img className='avatar' src={avatar} /> */}
            <p className='content'>{content}</p>
            <div className='name-title'>
                <p className='name'>{name}</p>
                <p className='title'>{title}</p>
            </div>
        </div>
    );
};