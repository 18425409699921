import React from 'react'
import './header.css'

const STYLES = [
  'header--page',
  'header--card',
  'header--hero',
  'header--pricing'
]

const THEME = [
    'header--dark',
    'header--light',
]

export const Header = ({
    children,
    headerStyle,
    theme
}) => {

    // check if passed dtyles exists => if not apply default syle
    const checkHeaderStyle = STYLES.includes(headerStyle) ? headerStyle : STYLES[0];
    const checkTheme = THEME.includes(theme) ? theme : THEME[0];

    return (
        <div className={`header ${checkHeaderStyle} ${checkTheme}`}>
            {children}
        </div>
    );
};