const getPrefilledMessage = (buttonType) => {
  console.log(buttonType);
  let message = '';
  if(buttonType === 'hero-button') message = 'Hi there, I\'d like to learn more about services you offer! Please, get back to me as soon as you can!';
  if(buttonType === 'programs') message = 'Hi there, I\'d like to learn more about programs you offer! Please, get back to me as soon as you can!';
  if(buttonType === 'nutrition-advisory') message = 'Hi there, I\'d like to learn more about nutrition advisory you offer! Please, get back to me as soon as you can!';
  if(buttonType === 'personal-training') message = 'Hi there, I\'d like to learn more about personal training you offer! Please, get back to me as soon as you can!';
  if(buttonType === 'online-programming') message = 'Hi there, I\'d like to learn more about online programming pricing! Please, get back to me as soon as you can!';
  if(buttonType === 'online-programming-nutrition') message = 'Hi there, I\'d like to learn more about online programming + nutrition package pricing! Please, get back to me as soon as you can!';
  if(buttonType === 'in-person-training') message = 'Hi there, I\'d like to learn more about personal training you offer! Please, get back to me as soon as you can!';
  return message;
};

const prefilledMessages = {
  getPrefilledMessage,
};

export default prefilledMessages;
