import React, { useEffect, useState } from 'react'
import './contact-form.css'
import emailjs from 'emailjs-com'
import { init } from 'emailjs-com';
import { Button } from '../button/button';
init('user_l1IHaGfWyqJ0t5rm5UDhu');

export const ContactForm = ({ prefilledMessage }) => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setMessage(prefilledMessage);
  }, [prefilledMessage])

  const submit = () => {
    if (firstName && email && message) {

      const name = firstName + ' ' + lastName;
      const source = 'thatcher-fitness';
      const serviceId = 'service_jli79o9';
      const templateId = 'template_l3kqn76';
      const userId = 'user_l1IHaGfWyqJ0t5rm5UDhu';
      const templateParams = {
        name,
        email,
        message,
        source
      };

      emailjs.send(serviceId, templateId, templateParams, userId)
        .then(response => console.log(response))
        .then(error => console.log(error));

      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      setEmailSent(true);
    } else {
      alert('Please fill in all fields.');
    }
  }

  return (
    <>
      <form>
        <div className='name'>
          <div className='label-input-wrapper'>
            <label htmlFor="first-name">FIRST NAME</label>
            <input className='first-name text' name='first-name' value={firstName} onChange={e => setFirstName(e.target.value)} />
          </div>
          <div className='label-input-wrapper'>
            <label htmlFor="last-name">LAST NAME</label>
            <input className='last-name text' type='last-name' value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>
        </div>
        <div className='label-input-wrapper'>
          <label htmlFor="last-name">EMAIL</label>
          <input className='email text' name='email' type='email' value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className='label-input-wrapper'>
          <label htmlFor="text">MESSAGE</label>
          <textarea className='text' rows="5" name='text' value={message} onChange={e => setMessage(e.target.value)} />
          {emailSent && <span>Thank you for your message, we will be in touch in no time!</span>}
        </div>
      </form>
      <Button onClick={submit} buttonStyle='btn--primary'>Send Message</Button>
    </>
  );
};