import React from 'react'
import { Link } from 'react-scroll';
import { Button } from '../button/button';
import { Header } from '../header/header';
import { Text } from '../text/text';
import './offer-card.css'

// const SIZES = [
//     'offer-card--md',
//     'offer-card--lg'
// ]

// const THEME = [
//     'light',
//     'dark'
// ]

export const OfferCard = ({
  header,
  text,
  imageSrc,
  cta,
  theme,
  type,
  setPrefilledMessage,
  size
}) => {
  var buttonStyle = theme === 'dark' ? 'btn--primary' : 'btn--default'
  return (
    <div className='offer-card' style={{ backgroundImage: "url(" + imageSrc + ")" }}>
      <Header headerStyle='header--card' theme={`header--${theme}`}>{header}</Header>
      <Text theme={`text--${theme}`}>{text}</Text>
      <Link to="contact-us" smooth={'true'} duration={500} offset={-80}>
        <Button setPrefilledMessage={setPrefilledMessage} type={type} buttonStyle={buttonStyle}>{cta}</Button>
      </Link>
    </div>
  );
};