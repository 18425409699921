import React from 'react'
// import { Button } from '../button/button';
import { Header } from '../header/header';
import { TestimonialSlider } from '../testimonial-slider/testimonial-slider';
import './testimonials.css'

export const Testimonials = () => {

    return (
        <div className='testimonials-section'>
            <Header headerStyle='page'>What our customers are saying</Header>
            <TestimonialSlider />
            {/* <Button buttonStyle='btn--default--bordered'>Write a Review</Button> */}
        </div>
    );
};