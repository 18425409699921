import React from 'react'
import { Link } from 'react-scroll';
import { Button } from '../button/button';
import { Header } from '../header/header';
import { Text } from '../text/text';
import './take-assessment.css'

export const TakeAssessment = ({
  header,
  text,
  cta
}) => {
  return (
    <div className='take-assessment'>
      <Header headerStyle='header--page' theme='header--dark'>{header}</Header>
      <Text theme='text--dark'>{text}</Text>
      <Link to="pricing" smooth={'true'} duration={500} offset={-80}>
        <Button type={'take-assessment'} buttonStyle='btn--primary'>{cta}</Button>
      </Link>
    </div>
  );
};